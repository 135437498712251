
.show-on-hover {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.show-on-hover-no-click {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.show-on-hover:hover {
  opacity: 1;
  cursor: pointer;
}

.show-on-hover-no-click:hover {
  opacity: 1;
}

.MuiImageListItemBar-title {
    vertical-align: text-top !important;
    white-space: unset !important;
}