/* Scrollbar styles */
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: #f5f5f5;
  border-radius: 25px;
}

::-webkit-scrollbar-thumb {
    border-radius: 25px;
    background: #ccc;
}

::-webkit-scrollbar-thumb:hover {
  background: #999;
}

.multi-dropdown {
  position: relative;
  border: 1px solid #183e4b;
  border-radius: 15px;
  height:50px;
  width: fit-content;
  /* border: 1px solid rgba(0, 0, 0, .15); */
}
.multi-dropdown.open > .multi-dropdown-menu {
  display: block;
}

.multi-dropdown-content {
  max-height: 359px;
  border-radius: 25px;
  overflow-x: hidden;
  -ms-overflow-x: hidden;
}
.multi-dropdown .multi-dropdown-arrow {
  display: inline-block;
  /* padding: 6px 12px; */
  margin-bottom: 0;
  margin-left: 15px;
  margin-right: 15px;
  font-weight: 300;
  font-size: 18px;
  /* line-height: 33px; */
  /* font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143; */
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  color: #183e4b;
  background-color: transparent;
  /* border-color: #2e6da4; */
  border: 1px solid transparent;
  border-radius: 4px;
}
.multi-dropdown .multi-dropdown-menu {
  position: absolute;
  top: 100%;
  right: -1.8em;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 1px;
  margin: 12px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #eaeaea;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border-radius: 25px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.multi-dropdown .multi-dropdown-menu:before,
.multi-dropdown .multi-dropdown-menu:after {
  content: " ";
  display: block;
  border-style: solid;
  border-width: 0 1em 0.8em 1em;
  border-color: transparent;
  position: absolute;
  right: 3em;
}

.multi-dropdown .multi-dropdown-menu:before {
  top: -0.8em;
}

.multi-dropdown .multi-dropdown-menu:after {
  top: -0.7em;
  border-bottom-color: #eaeaea;
}

.multi-dropdown .multi-dropdown-menu li {
  cursor: pointer;
}
.multi-dropdown .multi-dropdown-menu li :hover {
  background: #ddd;
}
.multi-dropdown .multi-dropdown-menu li > a {
  display: block;
  border-radius: 25px;
  padding: 15px 37px;
  clear: both;
  font-weight: 600;
  color: #183e4b;
  white-space: nowrap;
  text-decoration: none;
}
