.img-wrapper {
    position: relative;
  }
  
  .img-responsive {
    width: 100%;
  }
  
  .img-overlay-m,
  .img-overlay-b {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
  }
  
  .img-overlay-m:before {
      content: ' ';
      display: block;
      /* adjust 'height' to position overlay content vertically */
      height: 40%;
    }
  
  /* .img-overlay-b:before {
    content: ' ';
    display: block;
    height: 90%;
  } */
  
  .row-bottom {
      position:absolute;
      bottom:5px;
  }
  
  .watermark {
      font-weight: 800;
      font-size: 96px;
      line-height: 113px;
      color: rgba(255, 255, 255, 0.58);
      transform: rotate(-26.63deg);
  }
  .half {
    width: 50%;
  }
  .check-box {
    background-color: #EAEAEA;
    border-radius: 25px;
  }
.payment-border {
  border: solid black 2px
}
.pack-tight {
  width: 100px;
}
.cover-pack {
  width: 100%;
  height: 300px;
  object-fit: cover;    
}
.button-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

#or {
  position: relative;
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
}

#or::before,
#or::after {
  position: absolute;
  width: 160px;
  height: 1px;
  top: 24px;
  background-color: #aaa;
  content: '';
}

#or::before {
  left: 0;
}

#or::after {
  right: 0;
}

.modal-container {
   
  background-color: #DDDDDD;
}
.modal-header {
  background-color: #DDDDDD;
  border: #183E4B solid 1px;
}

.modal-body {
  border: #183E4B solid 1px;
  background-color: #DDDDDD;
}

.credit-card {
  width: 100%;
  height: 100%;
  object-fit: cover;    
}

.cc-form {
  /*
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 1rem;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
*/
}

.cc-input {
    /*
  display: flex;
  width: 300px;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin-bottom: 10px;
  writing-mode: horizontal-tb !important;
  text-rendering: auto;
  color: fieldtext;
  letter-spacing: normal;
  word-spacing: normal;
  line-height: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  appearance: auto;
  -webkit-rtl-ordering: logical;
  cursor: text;
  background-color: field;
  margin: 0em;
  padding: 1px 2px;
  border-width: 2px;
  border-style: inset;
  border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
  border-image: initial;
  */
}
