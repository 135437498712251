.profile-image {
    background-color: #8BA0A4;
    width: 140px;
    height: 140px;
}

.align-middle {
    vertical-align: middle;
}

.tab-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 30px;
    gap: 10px;
    color: #183E4B;
    height: 50px;
    border-radius: 15px;
    font-weight: 400;
    background-color: #fff;
    border: 2px solid #183E4B;
}

.tab-button.active {
    background-color: #183E4B;
    color: #fff;
}