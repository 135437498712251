.masonic {
  padding: 8px;
  width: 100%;
  max-width: 960px;
  margin: 163px auto;
}

.masonic-container {
    min-height: 100vh;
    width: 100%;
}

.featured-pack {

}
