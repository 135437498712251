.dropdown {
    position: relative;
}
.dropdown.open > .dropdown-menu {
    display: block;
}
.dropdown .dropdown-arrow {
    display: inline-block;
    /* padding: 6px 12px; */
    margin-bottom: 0;
    margin-left: 15px;
    margin-right: 15px;
    font-weight: 300;
    /* font-size: 28px;
    line-height: 33px; */
    /* font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143; */
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    color: #fff;
    background-color: transparent;
    /* border-color: #2e6da4; */
    border: 1px solid transparent;
    border-radius: 4px;
}
.dropdown .dropdown-menu {
    position: absolute;
    top: 100%;
    right: -1.8em;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 1px;
    margin: 12px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #EAEAEA;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    /*border: 1px solid #ccc;*/
    /*border: 1px solid rgba(0, 0, 0, .15);*/
    border-radius: 25px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
}

.dropdown .dropdown-menu:before,
.dropdown .dropdown-menu:after {
    content: ' ';
    display: block;
    border-style: solid;
    border-width: 0 1em .8em 1em;
    border-color: transparent;
    position: absolute;
    right: 3em;
}

.dropdown .dropdown-menu:before {
    top: -.8em;
}
  
.dropdown .dropdown-menu:after {
    top: -.7em;
    border-bottom-color: #EAEAEA;
}

.dropdown .dropdown-menu li {
    cursor: pointer;
}
.dropdown .dropdown-menu li :hover {
    background: #ddd;
}
.dropdown .dropdown-menu li > a {
    display: block;
    border-radius: 25px;
    padding: 15px 37px;
    clear: both;
    font-weight: 600;
    color: #183E4B;
    white-space: nowrap;
    text-decoration: none;
}
