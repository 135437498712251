.logo-si {
  width: 120px;
  height: 120px;
  margin: 0 auto;
  display: block;
  margin-bottom: 20px;
  border-radius: 10%;
}

.logo-platform {
    border: 2px;
    border-radius: 10%;

    
    width: 120px;
    height: 120px;
    margin: 0 auto;
    display: block;
    margin-bottom: 20px;
  }

  .text-platform {
    text-align: center;
    font-style: italic;
    color: #FFFFFF;
    margin: 0 auto;
    display: block;
  }

  .footer-link {
    color: #FFFFFF;
    font-weight: 600;
    font-size: 18px;
    text-decoration: none;
  }