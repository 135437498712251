.pricing-item {
    min-height: 575px;
}

.pricing-item-lg {
    min-height: 600px;
}

.card-header-sai {
    background-color: #183E4B;
}

.border-sai-secondary {
    border-color: #183E4B;
}

.card-title-sai {
    color: #183E4B !important;
}
.button-centered {
    text-align: center;
    align-items: center;
    justify-content: center;
}
.modal-container {
   
    background-color: #DDDDDD;
}
.modal-header {
    background-color: #DDDDDD;
    border: #183E4B solid 1px;
}

.modal-body {
    border: #183E4B solid 1px;
    background-color: #DDDDDD;
}


.cc-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 1rem;
    background-color: #fff;
    border-radius: 0.5rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
  
  }
  
  .cc-input {
    margin-top: 0.50rem !important;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 90%;
    margin-bottom: 10px;
    writing-mode: horizontal-tb !important;
    text-rendering: auto;
    color: fieldtext;
    letter-spacing: normal;
    word-spacing: normal;
    line-height: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: start;
    appearance: auto;
    -webkit-rtl-ordering: logical;
    cursor: text;
    background-color: field;
    margin: 0em;
    padding: 1px 2px;
    border-width: 2px;
    border-style: inset;
    border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
    border-image: initial;
  }
  