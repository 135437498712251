.collapsible {
  transition: transform 3s ease-out;
  transform-origin: top left;
}

.collapsed {
  transform: scaleY(0);
}

.fill {
  min-height: 67vh;
  max-height: 67vh;
}

.g_id_signin {
  margin-left: 7px;
}
