.tile-image {
  filter: brightness(50%);
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}

.tile-image:hover {
  -webkit-filter: brightness(100%);
  filter: brightness(100%);
}

.tile-container {
  display: inline-block;
  margin-right: auto;
  margin-left: auto;

  line-height: 0;
  -webkit-column-count: 1;
  -webkit-column-gap: 1.5rem;
  -moz-column-count: 1;
  -moz-column-gap: 1.5rem;
  column-count: 1;
  column-gap: 1.5rem;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

@media (min-width: 576px) {
  .tile-container {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
    -moz-column-gap: 1.5rem;
    -webkit-column-gap: 1.5rem;
    column-gap: 1.5rem;
  }
}

@media (min-width: 768px) {
  .tile-container {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
    -moz-column-gap: 1.5rem;
    -webkit-column-gap: 1.5rem;
    column-gap: 1.5rem;
  }
}

@media (min-width: 1200px) {
  .tile-container {
    -moz-column-count: 4;
    -webkit-column-count: 4;
    column-count: 4;
    -moz-column-gap: 1.5rem;
    -webkit-column-gap: 1.5rem;
    column-gap: 1.5rem;
  }
}
