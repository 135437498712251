.masonic {
  padding: 8px;
  width: 100%;
  max-width: 960px;
  margin: 163px auto;
}

.masonic-container {
    min-height: 100vh;
    width: 100%;
}

.select-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    background-color: #EAEAEA;
}

.bg-banner-info {
    background-color: #183E4B;
    color: #DDDDDD;
    font-size: large;
}

.register-text {
    color: white;
}