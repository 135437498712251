.img-wrapper {
  position: relative;
}

.img-responsive {
  width: 100%;
}

.img-overlay-m,
.img-overlay-b {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
}

.img-overlay-m:before {
    content: ' ';
    display: block;
    /* adjust 'height' to position overlay content vertically */
    height: 40%;
  }

/* .img-overlay-b:before {
  content: ' ';
  display: block;
  height: 90%;
} */

.row-bottom {
    position:absolute;
    bottom:5px;
}

.watermark {
    font-weight: 800;
    font-size: 96px;
    line-height: 113px;
    color: rgba(255, 255, 255, 0.58);
    transform: rotate(-26.63deg);
}

.check-box {
  background-color: #EAEAEA;
  border-radius: 25px;
}

.modal-container {
   
  background-color: #DDDDDD;
}
.modal-header {
  background-color: #DDDDDD;
  border: #183E4B solid 1px;
}

.modal-body {
  border: #183E4B solid 1px;
  background-color: #DDDDDD;
}

.reduced {
  opacity: 0.5;
  color: #EAEAEA;
  font-weight: 100;
  font-style: italic;
}

.size-options-text {
  color: white;
}