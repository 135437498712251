
$smartBlue: #183E4B;
$smartRed: #D74A49;
$smartLight: #EAEAEA;

$primary:       $smartRed;
$secondary:       $smartBlue;
$light: $smartLight;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';